import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import EasySoftwareVideoBanner, {
  EasySoftwareVideoBannerType,
} from '../EasySoftwareVideoBanner/EasySoftwareVideoBanner'

export interface WidgetEasySoftwareVideoBannerType
  extends WidgetBase,
    EasySoftwareVideoBannerType {
  contentType: 'EasySoftwareVideoBanner'
}

const WidgetEasySoftwareVideoBanner: FunctionComponent<WidgetEasySoftwareVideoBannerType> = (
  props
) => (
  <WidgetContainer {...props.puxWidgetProperties} {...props.metadata} fullWidth>
    <EasySoftwareVideoBanner
      displayText={props.displayText}
      contentItemId={props.contentItemId}
      videoBannerBackground={props.videoBannerBackground}
      videoBannerContent={props.videoBannerContent}
      videoBannerContentAlign={props.videoBannerContentAlign}
      puxButton={props.puxButton}
      videoBannerImageStyle={props.videoBannerImageStyle}
      videoBannerVideo={props.videoBannerVideo}
    />
  </WidgetContainer>
)

export default WidgetEasySoftwareVideoBanner
